html{
  height: 100%;
  width:100%;
}
body{
  width:100%;
  height:100%;
  margin:0px;
  background-color:whitesmoke;
  -webkit-overflow-scrolling: touch
}
body,
html {
  height: 100%;
  width: 100%;
  position: fixed;
}
#root{
  height: 100%!important;
  width: 100% !important;
}
a:link {
  color:#FFF67A
}
.App {
  text-align: center;
  height:100%;
  width:100%;
  background-color:white;
}

input[type=text] {   
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.saving span {
  animation-name: blink;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  
}

.saving span:nth-child(2) {

  animation-delay: .2s;
}

.saving span:nth-child(3) {
  animation-delay: .4s;
}
.messageBody{
  max-width:40%;
}

.MessageList{
  opacity:1;
  animation: fade 800ms linear;
}

.AnswerList{
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-top:2%; 
  flex-wrap:wrap
}

.App-link {
  color: #61dafb;
}
@media (max-width: 768px) {
  .messageBody{
    max-width:80%;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ticontainer{
  margin-top:10px;
  margin-bottom: 10px;
}

.tiblock {
  display: flex;
  height: 17px;
  text-align: right;
  flex-direction: row;
  justify-content: flex-end;
  margin-top:10px;
  margin-bottom: 10px;
}

.ticontainer .tidot {
  background-color: #90949c;
}

.tidot {
  -webkit-animation: mercuryTypingAnimation 1.5s infinite ease-in-out;
  border-radius: 8px;
  display: inline-block;
  height: 8px;
  margin-right: 4px;
  width: 8px;
}
@keyframes fade {
  100% { opacity: 1 }
  0% { opacity: 0 }
}

@-webkit-keyframes mercuryTypingAnimation{
0%{
-webkit-transform:translateY(0px)
}
28%{
-webkit-transform:translateY(-5px)
}
44%{
-webkit-transform:translateY(0px)
}
}

.tidot:nth-child(1){
-webkit-animation-delay:200ms;
}
.tidot:nth-child(2){
-webkit-animation-delay:300ms;
}
.tidot:nth-child(3){
-webkit-animation-delay:400ms;
}


.splashAnimation{
  animation-name: rotateLogo;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}
@keyframes rotateLogo {
  0% {
    transform: scale(0.9);
    opacity:0;
    animation-timing-function: linear;
  }
  10%{
    transform: scale(1);
    opacity:1;
    animation-timing-function: linear;
  }
  40%{
    transform:scale(1);
  }
  60%{
    transform: scale(0.90);
    animation-timing-function: cubic-bezier(.04,.13,.29,.98);
    opacity:1;
  }
  80%{
    transform: scale(3);
    opacity:0;
  }
  100% {
    opacity:0
  }
}
